<template>
    <div class="wrapper">
        <div class="bgfff contentwrap flex" style="    flex-direction: column;">
            <div class="tabBox">
                <div @click="handTab(1)" class="tabitem" :class="tabitem==1?'checked':''">渠道信息</div>
                <div @click="handTab(2)" class="tabitem" :class="tabitem==2?'checked':''">日志</div>
                <div class="channelCode">渠道编号：{{channelCode}}</div>
            </div>
            <!-- 渠道信息 -->
            <div v-show="tabitem==1">
                <div class="flex pl16 pr16 mt24 mb24 channelInfoMap">
                    <div style="width:50%;">
                        <div class="flex mb24">
                            <div class="label">类型：</div>
                            <div class="content">{{channelInfoMap.type==1?'个人':channelInfoMap.type==2?'公司':channelInfoMap.type==3?'集团资源':channelInfoMap.type==4?'分销商':''}}</div>
                        </div>
                        <div class="flex mb24">
                            <div class="label">渠道名称：</div>
                            <div class="content">{{channelInfoMap.name}}</div>
                        </div>
                        <div class="flex mb24">
                            <div class="label">联系人：</div>
                            <div class="content">{{channelInfoMap.realName}}</div>
                        </div>
                        <div class="flex mb24">
                            <div class="label">联系人身份证号：</div>
                            <div class="content">{{channelInfoMap.idcard}}</div>
                        </div>
                        <div class="flex mb24" v-if="channelInfoMap.type==2">
                            <div class="label">法人真实姓名：</div>
                            <div class="content">{{channelInfoMap.legalName}}</div>
                        </div>
                        
                        <div class="flex mb24">
                            <div class="label">公司电话：</div>
                            <div class="content">{{channelInfoMap.commPhone}}</div>
                        </div>
                        <div class="flex mb24">
                            <div class="label">渠道行业：</div>
                            <div class="content lastmb0">
                                <div class="item" style="margin-bottom:8px;" v-for="(item,index) in channelInfoMap.childchannelTradelist" :key="index">{{item.description}}</div>
                            </div>
                        </div>
                        <div class="flex mb24">
                            <div class="label" style="flex-shrink:0;">备注：</div>
                            <div class="content pr16">{{channelInfoMap.remark}}</div>
                        </div>
                    </div>
                    <div style="width:50%;">
                        <div class="flex mb24">
                            <div class="label">是否有协议：</div>
                            <div class="content">{{channelInfoMap.isTreatyName}}</div>
                        </div>
                        <div class="flex mb24">
                            <div class="label">手机号码：</div>
                            <div class="content">{{channelInfoMap.phoneShow}}</div>
                        </div>
                        <div class="flex mb24">
                            <div class="label">对接负责员工：</div>
                            <div class="content">{{channelInfoMap.empName}}</div>
                        </div>
                        <div class="flex mb24" v-if="channelInfoMap.type==2">
                            <div class="label">法人身份证号：</div>
                            <div class="content">{{channelInfoMap.legalIdcard}}</div>
                        </div>
                        <div class="flex mb24">
                            <div class="label" style="flex-shrink: 0;">渠道商通信地址：</div>
                            <!-- <el-input readonly  v-model="channelInfoMap.address" type="textarea" rows="3" class=""></el-input> -->
                            <div class="content">{{channelInfoMap.address}}</div>
                        </div>
                        <div class="flex mb24">
                            <div class="label">营业执照/身份证照：</div>
                            <div v-if="channelInfoMap.pdfList" class='flex'>
                                <div @click="handfile(item)" class="imgItem" v-for="(item,index) in channelInfoMap.pdfList" :key="index">
                                    <img class="inlineblock img c-pointer " src="../../assets/images/pdfview.png">

                                </div>
                            </div>
                            <viewer :images="channelInfoMap.fileList">
                                <div class="flex">
                                    <div class="imgItem" v-for="(item,index) in channelInfoMap.fileList" :key="index">
                                        <img class="inlineblock img c-pointer " :src="item.url">
                                    </div>
                                </div>
                            </viewer>
                        </div>
                    </div>

                </div>
                <!-- <div class="pl16 pr16 mb24">
                    <div class="flex mb24">
                        <div class="label" style="flex-shrink:0;">备注：</div>
                            <div style="color:rgba(0, 0, 0, 0.65);" class="content pr16">{{channelInfoMap.remark}}</div>
                    </div>
                </div> -->
                <div style="height: 1px;background: #EBEDF0; margin-left:16px;margin-right:16px;"></div>
                <list-tool-bar>
                    <el-button type="primary" style="margin-top:15px;margin-left:16px;" v-if="auth.marge&& tableData.length>0" @click.stop="handleAndCur">合并协议并转移客户</el-button>
                    <el-button type="primary" style="margin-top:15px;margin-left:16px;" v-if="auth.move && tableData.length>0" @click.stop="handleMoveCur">删除协议并转移客户</el-button>
                </list-tool-bar>

                <el-table :data="tableData" class="pl16 pr16" style="margin-bottom:16px;">
                    <el-table-column label="协议编号" prop="id" width="100"></el-table-column>
                    <el-table-column label="协议名称" prop="treatyname"></el-table-column>
                    <el-table-column label="协议生效时间" prop="starttime"></el-table-column>
                    <el-table-column label="协议结束时间" prop="endtime">
                    </el-table-column>
                    <el-table-column label="签约员工" prop="signempname">
                    </el-table-column>
                    <el-table-column label="签署方式" prop="signType">
                         <template slot-scope="scope">
                            <div>
                                {{scope.row.signTypeName}}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="账户名称" prop="accountName">
                    </el-table-column>
                    <el-table-column label="开户支行名" prop="bankName">
                    </el-table-column>
                    <el-table-column label="账户号码" prop="bankNum">
                    </el-table-column>
                    <el-table-column label="回传协议" prop="endtime">
                        <template slot-scope="scope">
                            <div v-if="scope.row.backhaulPath">
                                <div v-if="scope.row.pdfList2.length">
                                    <div @click="handPdf(item)" style="color:#2878FF;cursor: pointer;margin-top: 5px;" v-for="(item,index) in scope.row.pdfList2" :key="index">
                                        附件{{index+1}}
                                    </div>
                                </div>
                                <viewer :images="scope.row.fileList2" v-if="scope.row.fileList2.length">
                                    <div style="width:30px;height:30px;cursor: pointer;">
                                        <div style="color:#2878FF;cursor: pointer;position: absolute;margin-top: 5px;">预览</div>
                                        <div style="width:30px;height:30px;opacity: 0;position: absolute;" v-for="(item,index) in scope.row.fileList2" :key="index">
                                            <img style="width:30px;height:30px;" :src="item.url">
                                        </div>
                                    </div>
                                </viewer>

                            </div>
                         
                            <div v-else></div>
                        </template>
                    </el-table-column>
                    <el-table-column label="协议附件" prop="path">
                        <template slot-scope="scope">
                            <div v-if="scope.row.path">
                                <div v-if="scope.row.pdfList.length">
                                    <div @click="handPdf(item)" style="color:#2878FF;cursor: pointer;margin-top: 5px;" v-for="(item,index) in scope.row.pdfList" :key="index">
                                        附件{{index+1}}
                                    </div>
                                </div>
                                <viewer :images="scope.row.fileList" v-if="scope.row.fileList.length">
                                    <div style="width:30px;height:30px;cursor: pointer;">
                                        <div style="color:#2878FF;cursor: pointer;position: absolute;margin-top: 5px;">预览</div>
                                        <div style="width:30px;height:30px;opacity: 0;position: absolute;" v-for="(item,index) in scope.row.fileList" :key="index">
                                            <img style="width:30px;height:30px;" :src="item.url">
                                        </div>
                                    </div>
                                </viewer>
                            </div>

                        </template>

                    </el-table-column>
                    <el-table-column label="审核状态" prop="verifystatename">
                        <template slot-scope="scope">
                            <div class="flex a-center">
                                <div class="verifystate" :class="'verifystate'+scope.row.verifystate"></div> {{scope.row.verifystatename}}
                            </div>
                        </template>
                    </el-table-column>

                    <!-- <el-table-column label="操作"
                        fixed="right">
                        <template slot-scope="scope">
                            <div class="operator-cell">
                                <el-button
                                    v-if="true||auth.save"
                                    type="text"
                                    @click.stop="handleDetail(scope.row)"
                                >详情</el-button>
                             
                                <el-button
                                    v-if="true||auth.delete"
                                    type="text"
                                    @click.stop="handleDelete(scope.row)"
                                >删除</el-button>
                            </div>
                        </template>
                    </el-table-column> -->
                </el-table>

                <!-- 特殊申请附件 -->
                <div class="flex a-center addrow" style="margin-left:16px;margin-bottom:16px;" v-if="tableSpecialData.length>0">
                    <div class="fb" style="font-weight:600;">特殊申请附件</div>
                </div>
                <el-table v-if="tableSpecialData.length>0" :data="tableSpecialData" class="pl16 pr16" style="margin-bottom:16px;">
                     <el-table-column label="特殊申请类型" prop="typeName"></el-table-column>
                    <el-table-column label="申请有效期" prop="applyStartTimeName">
                        <template slot-scope="scope">
                            <div>
                                {{scope.row.applyStartTimeName}} - {{scope.row.applyEndTimeName}}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="申请附件" prop="file">
                        <template slot-scope="scope">
                            <div v-if="scope.row.file">
                                <div v-if="scope.row.pdfList.length">
                                    <div @click="handPdf(item)" style="color:#2878FF;cursor: pointer;margin-top: 5px;" v-for="(item,index) in scope.row.pdfList" :key="index">
                                        附件{{index+1}}
                                    </div>
                                </div>
                                <viewer :images="scope.row.fileList" v-if="scope.row.fileList.length">
                                    <div style="width:30px;height:30px;cursor: pointer;">
                                        <div style="color:#2878FF;cursor: pointer;position: absolute;margin-top: 5px;">预览</div>
                                        <div style="width:30px;height:30px;opacity: 0;position: absolute;" v-for="(item,index) in scope.row.fileList" :key="index">
                                            <img style="width:30px;height:30px;" :src="item.url">
                                        </div>
                                    </div>
                                </viewer>
                            </div>
                        </template>
                    </el-table-column>
                     <el-table-column label="创建人" prop="createEmpName" width="260"></el-table-column>
                    <el-table-column label="创建时间" prop="createTimeName"></el-table-column>
                </el-table>

            </div>
            <!-- 日志 -->
            <div v-show="tabitem==2" style="margin-top:24px;    flex: 1;display: flex;flex-direction: column;overflow: auto;">
                <el-table :data="hisTableData" class="pl16 pr16" style="margin-bottom:16px;    flex: 1;overflow: auto;">
                    <el-table-column label="时间" prop="createtime" width="160">
                        <template slot-scope="scope">
                            <div v-html="scope.row.createtime"></div>
                        </template>
                    </el-table-column>
                    <el-table-column label="类型" prop="typeName" width="160"></el-table-column>
                    <el-table-column label="变更前的值" prop="beforevalue"></el-table-column>
                    <el-table-column label="变更后的值" prop="aftervalue">
                    </el-table-column>
                    <el-table-column label="操作人" prop="createName" width="160">
                    </el-table-column>
                    <!-- <el-table-column label="操作"
                        fixed="right">
                        <template slot-scope="scope">
                            <div class="operator-cell">
                                <el-button
                                    v-if="true||auth.save"
                                    type="text"
                                    @click.stop="handleDetail(scope.row)"
                                >详情</el-button>
                             
                                <el-button
                                    v-if="true||auth.delete"
                                    type="text"
                                    @click.stop="handleDelete(scope.row)"
                                >删除</el-button>
                            </div>
                        </template>
                    </el-table-column> -->
                </el-table>
                <el-row style="text-align: right;padding-right: 16px;margin-bottom: 16px;">
                    <el-pagination layout="total, sizes, prev, pager, next, jumper" :current-page.sync="pageNum" :page-size="pageSize" :total="total" @size-change="handlePageSizeChange" @current-change="handlePageNoChange"></el-pagination>

                </el-row>
                <!-- <list-foot-bar>
                </list-foot-bar> -->

            </div>
        </div>
        <div class="foot-bar">
            <el-button @click="handleClose">返回</el-button>
        </div>
        <!--合并协议并转移客户  -->
        <el-dialog class="contract-edit" :visible="viewIsReady" @close="handleReadyClose" title="合并协议并转移客户" width="660px">
            <div id="box" ref="box" style="margin-bottom:24px;">
                <div class="marquee-box flex a-center" ref="marquee">

                    <p ref="cmdlist" id="pWidth" style="display: flex;">
                        <img style="margin-right:8px;width:16px;height:16px;margin-left:12px;" src="../../assets/images/info-circle.png" alt="">
                        <span style="color:red;">温馨提示：有认购信息不能进行合并操作，请先去业务系统修改后再进行合并。</span>
                        
                    </p>
                </div>
            </div>

            <el-form class="private handleReadySave" :model="PB" :rules="rules" label-width="150px" label-suffix="：" ref="form" @submit.prevent="handleReadySave">

                <el-form-item label="合并至渠道商名称" prop="chanId">
                    <el-autocomplete placeholder="请输入" v-model="PB.chanName" :fetch-suggestions="querySearch" @select="handleSelect($event, 'chanId')" class="width3"></el-autocomplete>
                </el-form-item>

            </el-form>

            <div slot="footer">
                <el-button @click="handleReadyClose">取消</el-button>
                <el-button @click="handleReadySave" type="primary">保存</el-button>
            </div>
        </el-dialog>
        <!--删除协议并转移客户  -->
        <el-dialog class="contract-edit" :visible="viewMove" @close="handleMoveClose" title="删除协议并转移客户" width="840px">
            <div id="box2" ref="box2" style="margin-bottom:24px;">
                <div class="marquee-box flex a-center" ref="marquee2">

                    <p ref="cmdlist2" id="pWidth2" style="display: flex;">
                        <img style="margin-right:8px;width:16px;height:16px;margin-left:12px;" src="../../assets/images/info-circle.png" alt="">
                        <span style="color:red;">温馨提示：有关联业务的不能直接进行合并操作，请先去业务系统修改后再进行合并。</span>
                        
                    </p>
                </div>
            </div>

            <el-form class="private handleMoveSave" :model="moveData" :rules="moveRules" label-width="150px" label-suffix="：" ref="moveform" @submit.prevent="handleMoveSave">
                <el-form-item label="当前渠道商名称">
                    <div> {{channelInfoMap.code}} - {{channelInfoMap.name}} - {{channelInfoMap.phoneShow}} - {{channelInfoMap.empName}}</div>
                </el-form-item>
                <el-form-item label="合并至渠道商名称" prop="chanId">
                    <el-autocomplete placeholder="请输入" v-model="moveData.chanName" :fetch-suggestions="querySearchMove" @select="handleSelectMove($event, 'chanId')" class="width3"></el-autocomplete>
                </el-form-item>
                <el-table :data="moveData.movetableData" class="pl16 pr16" style="margin-bottom:30px;">
                    <el-table-column label="协议编号" prop="id" width="60"></el-table-column>
                    <el-table-column label="协议名称" prop="treatyname" width="80"></el-table-column>
                    <el-table-column label="协议日期" prop="starttime" width="105">
                         <template slot-scope="scope">
                           <div>{{scope.row.starttime}} -</div>
                           <div>{{scope.row.endtime}}</div>
                        </template>
                    </el-table-column>
                    <!-- <el-table-column label="协议生效时间" prop="starttime" width="110"></el-table-column>
                    <el-table-column label="协议结束时间" prop="endtime" width="110">
                    </el-table-column> -->
                    <el-table-column label="合并至协议" prop="">
                        <template slot-scope="scope">
                            <el-form-item :prop="`movetableData[${scope.$index}].chooseId`" :rules="moveRules.chooseId" class="tableForm">
                                <el-select v-model="scope.row.chooseId" style="width:calc(100% - 30px)">
                                    <el-option v-for="e in scope.row.treatyList" :key="e.id" :label="e.showName" :value="e.id"></el-option>
                                </el-select>
                            </el-form-item>
                        </template>
                    </el-table-column>

                    
                </el-table>

            </el-form>

            <div slot="footer">
                <el-button @click="handleMoveClose">取消</el-button>
                <el-button @click="handleMoveSave" type="primary">保存</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    components: {},
    props: {},
    data() {
        return {
            id: "",
            channelCode: "",
            tabitem: 1,
            channelInfoMap: "",
            tableData: [],
            movetableData: [],
            hisTableData: [],
            pageNum: 1,
            pageSize: 10,
            total: 0,
            viewIsReady: false,
            PB: {
                chanName: "",
                chanId: "",
            },
            // 验证规则
            rules: {
                chanId: {
                    required: true,
                    message: "请查询选择渠道商",
                    trigger: "change",
                },
                // signempid      : { required: true, message: "请选择签约员工",   trigger: "blur" },
                // imgPath      : { required: true, message: "请上传附件",   trigger: ["change","blur"] },
            },

            viewMove: false,
            moveData: {
                chanName: "",
                chanId: "",
                movetableData: [],
            },

            // 验证规则
            moveRules: {
                chanId: {
                    required: true,
                    message: "请查询选择渠道商",
                    trigger: "change",
                },
                chooseId: {
                    validator(rule, val, cb) {
                        console.log(val);
                        if (val) cb();
                        else cb("请选择协议");
                    },
                },
            },
            auth:{
                marge:false,
                move:false,
            },
            tableSpecialData:[],
        };
    },
    created() {
        this.auth.marge = this.$hasAuthFor("/api/channel/mergeChannel/marge");
        this.auth.move = this.$hasAuthFor("/api/channel/mergeChannel/move");



        console.log(this.$route);
        this.id = this.$route.query.id;
        this.channelCode = this.$route.query.channelCode;
        this.getDetail();
        this.getChannelHisList();
    },
    mounted() {},
    watch: {},
    computed: {},
    methods: {
        async querySearch(queryString, cb) {
            try {
                const res = await this.$axios({
                    url: "/api/channel/queryLikeChannel",
                    method: "post",
                    data: {
                        searchValue: queryString,
                    },
                });

                if (res.code === 2000) {
                    cb(
                        res.data.channelList.map((item) => {
                            item.value = [
                                item.code,
                                item.name,
                                item.showPhone,
                                item.empName,
                            ]
                                .filter(Boolean)
                                .join(" - ");
                            return item;
                        })
                    );
                } else if (res.code !== 1003) throw res;
            } catch (reason) {
                console.warn("模糊查询失败", reason);
                cb([]);
            }
        },

        handleSelect(item, key) {
            this.PB.chanName = item.name;
            this.PB.chanId = item.id;
        },

        handleAndCur() {
            this.viewIsReady = true;
        },
        handleReadyClose() {
            // clearInterval(this.timer);
            this.viewIsReady = false;
            // this.scrollvalue= -600
            this.PB = {
                // id:'',
                chanName: "",
                chanId: "",
            };
            setTimeout(() => {
                this.$refs.form.clearValidate();
            }, 20);
            // this.$emit("update:item", null);
        },
        async handleReadySave() {
            const loadingRef = this.$loading({
                target: ".chandleReadySave .el-dialog",
            });

            try {
                await this.$refs.form.validate();
                const res = await this.$axios({
                    url: "/api/channel/mergeChannel",
                    method: "post",
                    data: {
                        type: 1, //合并类型 1 合并协议并转移客户 2删除协议并转移客户
                        sourceChannelId: this.id, //原渠道ID
                        targetChannelId: this.PB.chanId, //目标渠道ID
                    },
                });

                if (res.code === 2000) {
                    this.$message({
                        message: "保存成功",
                        type: "success",
                    });
                    loadingRef && loadingRef.close();
                    // this.$emit("change",res.data.id);
                    this.handleReadyClose();
                    this.getDetail();
                    this.getChannelHisList();
                } else if (res.code !== 1003) throw res;
            } catch (reason) {
                reason &&
                    this.$message({
                        message: reason.msg || "保存失败",
                        type: "error",
                    });
                console.warn(reason);
                loadingRef && loadingRef.close();
            }
        },
        async handleReadySaveOld() {
            if (this.posting)
                return this.$message({
                    message: "请等待上传完成",
                    type: "warning",
                });
            const loadingRef = this.$loading({
                target: ".contract-edit .el-dialog",
            });

            try {
                let _imgPath = [];
                if (this.PB.signtype === "1") {
                    this.fileList = [];
                    this.pdfList = [];
                } else {
                    this.fileList.forEach((ele) => {
                        _imgPath.push(ele.path);
                    });
                    this.pdfList.forEach((ele) => {
                        _imgPath.push(ele.path);
                    });
                    this.PB.imgPath = _imgPath;
                }
                await this.$refs.form.validate();

                const res = await this.$axios({
                    url: "/api/channel/saveChannelTreaty",
                    method: "post",
                    data: {
                        id: this.PB.id,
                        confid: this.PB.confid, //协议id
                        starttime: this.PB.starttime, //开始时间
                        endtime: this.PB.endtime, //结束时间
                        signempid: this.PB.signempid, //签约员工id
                        channelid: this.chanid, //渠道id
                        signtype: this.PB.signtype, //签约类型 1线上 0线下
                        bankname: this.PB.bankname, //开户支行名
                        accountname: this.PB.accountname, //账户名称
                        banknum: this.PB.banknum, //账户号码
                        path: _imgPath.join(),
                    },
                });

                if (res.code === 2000) {
                    this.$message({
                        message: "保存成功",
                        type: "success",
                    });
                    loadingRef && loadingRef.close();
                    this.$emit("change", res.data.id);

                    this.handleClose();
                } else if (res.code !== 1003) throw res;
            } catch (reason) {
                reason &&
                    this.$message({
                        message: reason.msg || "保存失败",
                        type: "error",
                    });
                console.warn(reason);
                loadingRef && loadingRef.close();
            }
        },

        handleMoveCur() {
            this.viewMove = true;
        },
        handleMoveClose() {
            // clearInterval(this.timer);
            this.viewMove = false;
            // this.scrollvalue= -600
        

            this.moveData.chanName=''
            this.moveData.chanId=''

            this.moveData.movetableData.forEach((element) => {
                element.treatyList = [];
                element.chooseId = '';
            });
            setTimeout(() => {
                this.$refs.moveform.clearValidate();
            }, 20);
            // this.$emit("update:item", null);
        },
        async querySearchMove(queryString, cb) {
            try {
                const res = await this.$axios({
                    url: "/api/channel/queryLikeChannel",
                    method: "post",
                    data: {
                        searchValue: queryString,
                    },
                });

                if (res.code === 2000) {
                    cb(
                        res.data.channelList.map((item) => {
                            item.value = [
                                item.code,
                                item.name,
                                item.showPhone,
                                item.empName,
                            ]
                                .filter(Boolean)
                                .join(" - ");
                            return item;
                        })
                    );
                } else if (res.code !== 1003) throw res;
            } catch (reason) {
                console.warn("模糊查询失败", reason);
                cb([]);
            }
        },

        handleSelectMove(item, key) {
            this.moveData.chanName = item.name;
            this.moveData.chanId = item.id;
             this. moveData.movetableData.forEach(element => {
                element.chooseId = ''
             });
            this.getSelectData(item.id);
        },
        // 查询协议列表
        async getSelectData(id) {
            let _this = this;
            let _data = {};
            _data = {
                channelid: id,
            };
            const res = await _this.$axios({
                url: "/api/channel/queryChannelTreaty",
                method: "post",
                data: _data,
            });
            if (res.code === 2000) {
                res.data.channelTreatyList.forEach((element) => {
                    element.showName =
                        element.id +
                        " - " +
                        element.treatyname +
                        " - " +
                        element.starttime +
                        " - " +
                        element.endtime;
                });

                _this.$nextTick().then(() => {
                    _this.moveData.movetableData.forEach((item) => {
                        item.treatyList = res.data.channelTreatyList;
                    });
                });
            }
        },
        async handleMoveSave() {
            const loadingRef = this.$loading({
                target: ".handleMoveSave .el-dialog",
            });

            try {
                await this.$refs.moveform.validate();
                let treatyInfo = [];
                this.moveData.movetableData.forEach((element) => {
                    let obj = {};
                    obj.sourceTreatyId = element.id;
                    obj.targetTreatyId = element.chooseId;
                    treatyInfo.push(obj);
                });
                const res = await this.$axios({
                    url: "/api/channel/mergeChannel",
                    method: "post",
                    data: {
                        type: 2, //合并类型 1 合并协议并转移客户 2删除协议并转移客户
                        sourceChannelId: this.id, //原渠道ID
                        targetChannelId: this.moveData.chanId, //目标渠道ID
                        treatyInfo: JSON.stringify(treatyInfo),
                    },
                });

                if (res.code === 2000) {
                    this.$message({
                        message: "保存成功",
                        type: "success",
                    });
                    loadingRef && loadingRef.close();
                    // this.$emit("change",res.data.id);
                    this.handleMoveClose();
                    this.getDetail();
                    this.getChannelHisList();
                } else if (res.code !== 1003) throw res;
            } catch (reason) {
                reason &&
                    this.$message({
                        message: reason.msg || "保存失败",
                        type: "error",
                    });
                console.warn(reason);
                loadingRef && loadingRef.close();
            }
        },

        handfile(item) {
            window.open(item.url);
        },
        handPdf(item) {
            window.open(item.url);
        },
        // 翻页相关
        handlePageSizeChange(size) {
            this.pageSize = size;
            this.getChannelHisList();
        },
        // 翻页相关
        handlePageNoChange(no) {
            this.pageNum = no;
            this.getChannelHisList();
        },
        async getDetail() {
            let loadingRef;
            try {
                loadingRef = this.$loading();
                const res = await this.$axios({
                    url: "/api/commChan/queryChannelDetail",
                    method: "post",
                    data: {
                        chanCode: this.channelCode,
                    },
                     headers:{
                        'loginEmpCode':this.userInfo.usercode,
                    },
                    
                });
                if (res.code === 2000) {
                    this.channelInfoMap = res.data.channelInfoMap;
                    this.channelInfoMap.phoneShow = "";
                    if (this.channelInfoMap.phone) {
                        this.channelInfoMap.phoneShow =
                            this.channelInfoMap.phone.replace(
                                /(\d{3})\d{4}(\d{4})/,
                                "$1****$2"
                            );
                    }

                    // this.channelInfoMap.imgPath = 'upload/real/168619351199440.jpg,upload/real/168619395672615.jpg'
                    this.channelInfoMap.fileList = [];
                    this.channelInfoMap.pdfList = [];

                    if (this.channelInfoMap.imgPath) {
                        let imgPathArr = this.channelInfoMap.imgPath.split(",");
                        imgPathArr.forEach((element) => {
                            //

                            const suffix = element.substring(
                                element.lastIndexOf(".") + 1
                            );

                            let headurl = "";
                            let fullurl = "";
                            if (element.length > 4) {
                                headurl = element.slice(0, 4);
                            }
                            if (headurl == "http") {
                                fullurl = element;
                            } else {
                                fullurl = this.$baseURL + "/" + element;
                            }
                            //
                            if (suffix == "pdf") {
                                this.channelInfoMap.pdfList.unshift({
                                    name: "",
                                    url: fullurl,
                                    path: element,
                                });
                            } else {
                                this.channelInfoMap.fileList.unshift({
                                    name: "",
                                    url: fullurl,
                                    path: element,
                                });
                            }

                            // this.channelInfoMap.fileList.unshift({
                            //         name: '',
                            //         url: fullurl,
                            //         path: element
                            //     }
                            // )
                        });
                    }
                    res.data.channelTreatyList.forEach((element) => {
                        element.treatyList = [];
                        element.chooseId = "";
                    });

                    this.moveData.movetableData = res.data.channelTreatyList;
                    this.tableData = res.data.channelTreatyList;
                    this.tableData.forEach((item) => {
                        item.fileList = [];
                        item.pdfList = [];
                        item.fileList2 = [];
                        item.pdfList2 = [];
                        item.treatyList = [];
                        if (item.path) {
                            let imgPathArr = item.path.split(",");
                            imgPathArr.forEach((element) => {
                                const suffix = element.substring(
                                    element.lastIndexOf(".") + 1
                                );

                                let headurl = "";
                                let fullurl = "";
                                if (element.length > 4) {
                                    headurl = element.slice(0, 4);
                                }
                                if (headurl == "http") {
                                    fullurl = element;
                                } else {
                                    fullurl = this.$baseURL + "/" + element;
                                }
                                if (suffix == "pdf") {
                                    item.pdfList.unshift({
                                        name: "",
                                        url: fullurl,
                                        path: element,
                                    });
                                } else {
                                    item.fileList.unshift({
                                        name: "",
                                        url: fullurl,
                                        path: element,
                                    });
                                }
                            });
                        }
                        if (item.backhaulPath) {
                            let imgPathArr = item.backhaulPath.split(",");
                            imgPathArr.forEach((element) => {
                                const suffix = element.substring(element.lastIndexOf('.') + 1)
                                if(suffix=='pdf'){
                                    item.pdfList2.unshift({
                                        name: "",
                                        url: this.$baseURL + "/" + element,
                                        path: element,
                                    });
                                    
                                }else{
                                    item.fileList2.unshift({
                                        name: "",
                                        url: this.$baseURL + "/" + element,
                                        path: element,
                                    });
                                }
                            });
                        }
                        //
                    });
                    this.tableSpecialData = res.data.specialFileList;
                    this.tableSpecialData.forEach((item) => {
                    item.fileList = [];
                    item.pdfList = [];
                    
                    if (item.file) {
                        let imgPathArr = item.file.split(",");
                        imgPathArr.forEach((element) => {
                            const suffix = element.substring(
                                element.lastIndexOf(".") + 1
                            );
                            if (suffix == "pdf") {
                                item.pdfList.unshift({
                                    name: "",
                                    url: this.$baseURL + "/" + element,
                                    path: element,
                                });
                            } else {
                                item.fileList.unshift({
                                    name: "",
                                    url: this.$baseURL + "/" + element,
                                    path: element,
                                });
                            }
                        });
                    }
                });



                    



                    // this.$message({
                    //     message: "删除成功",
                    //     type: "success"
                    // });
                    loadingRef && loadingRef.close();
                    // this.fetchData();
                } else if (res.code !== 1003) throw res;
            } catch (reason) {
                console.log(reason);
                if (reason !== "cancel") {
                    this.$message({
                        message: reason.msg || "获取失败",
                        type: "warning",
                    });
                }
                loadingRef && loadingRef.close();
            }
        },
        // 日志
        async getChannelHisList() {
            try {
                const res = await this.$axios({
                    url: "/api/commChan/queryChannelHisList",
                    method: "post",
                    headers:{
                        'loginEmpCode':this.userInfo.usercode,
                    },
                    data: {
                        // chanCode: this.channelCode,
                        pageNum: this.pageNum,
                        pageSize: this.pageSize,
                        id: this.id,
                    },
                });
                if (res.code === 2000) {
                    this.hisTableData = res.data.channelHisList;

                    this.total =
                        res.data.count || res.data.channelHisList.length;
                } else if (res.code !== 1003) throw res;
            } catch (reason) {
                if (reason !== "cancel") {
                    this.$message({
                        message: reason.msg || "获取失败",
                        type: "warning",
                    });
                }
                // loadingRef && loadingRef.close();
            }
        },

        // 返回
        handleClose() {
            this.$router.go(-1);
        },
        // 切换tab
        handTab(index) {
            // if(index!=this.tabitem){

            // }
            this.tabitem = index;
        },
    },
};
</script>
<style lang="scss" scoped>
.wrapper {
    padding: 16px;
    display: flex;
    flex-direction: column;
    height: 100%;
}
.bgfff {
    background: #fff;
}
.contentwrap {
    flex: 1;
    overflow: auto;
}
.foot-bar {
    z-index: 9;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 56px;
    padding-left: 16px;
    padding-right: 16px;
    background-color: white;
    box-shadow: 0px -2px 4px 0px rgba(200, 201, 204, 0.35);
}
.tabBox {
    border-bottom: 1px solid #ebedf0;
    height: 48px;
    display: flex;
    align-items: flex-end;
    padding-left: 16px;
}
.tabBox .tabitem {
    padding: 0 20px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    background: #ffffff;
    border-radius: 8px 8px 0px 0px;
    border: 1px solid #ebedf0;
    margin-right: 8px;
    border-bottom: none;
    cursor: pointer;
}
.tabBox .checked {
    background: #2878ff;
    color: #fff;
}

.flex {
    display: flex;
}
.a-center {
    align-items: center;
}

.pl16 {
    padding-left: 16px;
}
.pr16 {
    padding-right: 16px;
}
.mb16 {
    margin-bottom: 16px;
}
.mb24 {
    margin-bottom: 24px;
}
.mt24 {
    margin-top: 24px;
}
.channelInfoMap .content {
    color: rgba(0, 0, 0, 0.65);
}
.channelCode {
    margin-left: auto;
    height: 48px;
    line-height: 48px;
    margin-right: 16px;
    font-weight: 600;
}
.imgItem {
    width: 80px;
    height: 80px;
    margin-right: 12px;
    position: relative;
}
.imgItem .img {
    width: 80px;
    height: 80px;
    border-radius: 6px;
}
.verifystate {
    width: 4px;
    height: 4px;
    border-radius: 50%;
    margin-right: 4px;
    background: #e02020;
}
.verifystate0 {
    background: #e02020;
}
.verifystate1 {
    background: #6dd400;
}
.c-pointer {
    cursor: pointer;
}
.lastmb0 .item:nth-last-child(1) {
    margin-bottom: 0 !important;
}
.tableForm /deep/ .el-form-item__content {
    margin-left: 0 !important;
}
</style>